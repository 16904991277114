<template>
    <div class="live-warmup">
        <div class="title">短视频管理</div>
        <div class="release" @click="toRelease">
            <img class="img" src="../../../assets/image/releaseVideo.png" alt="">
            <div class="label">发布视频</div>
        </div>
        <div class="table">
            <el-table
                class="el-table"
                :data="tableData"
                height="100%"
                style="width: 100%"
            >
                <el-table-column prop="nickname" label="视频信息" width="180">
                    <template slot-scope="scope">
                        <div class="nickname" v-html="scope.row.nickname" :title="tip(scope.row.nickname)"></div>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" width="180" align="center"></el-table-column>
                <el-table-column prop="time" label="发布时间" align="center"></el-table-column>
                <el-table-column prop="" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="edit(scope.row, scope.$index)">编辑</el-link>
                        <el-link type="danger" :underline="false" @click="del(scope.row)">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LiveWarmup',
    components: {},
    props: {},
    data() {
        return {
            tableData: localStorage.getItem("warmupList") ? JSON.parse(localStorage.getItem("warmupList")) : []
        }
    },
    computed: {},
    methods: {
        toRelease() {
            this.$router.push("/liveWarmup/release")
        },
        edit(item, index) {
            localStorage.setItem("targetWarmup", JSON.stringify(item));
            this.$router.push({
                path: "/liveWarmup/release",
                query: {
                    id: index
                }
            })
        },
        async del(item) {
            const res = await this.$confirm('删除后不可恢复，是否删除', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => {});
            if(res) {
                const index = this.tableData.findIndex(subItem => item == subItem);
                if(index !== undefined) {
                    this.tableData.splice(index, 1);
                    localStorage.setItem("warmupList", JSON.stringify(this.tableData));
                }
            }
        },
        tip(html) {
            const con = document.createElement("div");
            con.innerHTML = html;
            return con.innerText;
        }
    }
}
</script>

<style scoped lang="scss">
.live-warmup {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 37px 20px 30px;
    color: #fff;
    .title {
        font-size: 16px;
        font-weight: 500;
    }
    .release {
        position: relative;
        width: 178px;
        margin: 20px 0;
        cursor: pointer;
        .img {
            width: 100%;
        }
        .label {
            position: absolute;
            top: 57px;
            left: 50%;
            transform: translate(-50%);
        }
    }
    .table {
        flex: 1;
        overflow: hidden;
        /deep/ .el-table {
            border: 1px solid #262963;
            background-color: transparent;
            &::before {
                background-color: #262963;
            }
            .el-table__header .has-gutter tr,
            .el-table__header .has-gutter th {
                background-color: #0c0e3f;
                height: 60px;
            }
            .el-table__header .has-gutter th {
                border-color: #262963;
            }

            .el-table__body .el-table__row {
                background-color: transparent;
                color: #fff;
                &:hover .el-table__cell{
                    background-color: transparent;
                }
                .el-table__cell {
                    border-color: #262963;
                    .nickname {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        .topic {
                            color: #fff;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    
}
</style>
